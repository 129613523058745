@import 'src/scss/_variables.scss';

.wrapper {
  max-width: 1270px + $contMargin;
  margin: 0 auto;
}

.container {
  position: relative;
  margin: 0 $contMargin;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .content {
    margin: 0 auto;
    width: 635px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e2e2e2;
    z-index: 1;
  }
}