.link {
  position: relative;
  display: inline-block;
  font-family: 'Playfair Display', "Times New Roman", system-ui, Tahoma, serif;
  font-size: clamp(1.205rem, calc(0.5rem + 1.25vw), 1.215rem);
  margin-bottom: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1;

  @media (max-width: 500px) {
    margin-bottom: 15px;
  }

  &:hover {
    cursor: pointer;

    &::after {
      -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transform-origin: bottom left;
      -ms-transform-origin: bottom left;
      transform-origin: bottom left;
    }
  }

  &:before {
    position: absolute;
    content: '';
    background: url(../../../../../assets/meander.svg) no-repeat center;
    background-size: 100% 100%;
    width: 24px;
    height: 20px;
    top: 0;
    left: -34px;

    @media (max-width: 500px) {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #877065;
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
    -webkit-transition: -webkit-transform 0.25s ease-out;
    transition: -webkit-transform 0.25s ease-out;
    -o-transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}