.thematicBreak {
  width: 100%;
  margin: 30px 0;
  padding: 0 30px;

  @media (max-width: 620px) {
    padding: 0 5px;
    margin: 15px 0;
  }
}
