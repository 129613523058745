// @media (min-width: 1920px) {}
@import '../variables';

.wrapper {
  max-width: 1270px + $contMargin;
  margin: 0 auto;
}

.container {
  position: relative;
  height: 276px;
  margin: 0 $contMargin;
  background: url(../../assets/headerImg.jpg) no-repeat center;
  display: flex;
  justify-content: space-between;

  .lion1 {
    position: absolute;
    bottom: 0;
    left: 3vw;
    width: 323px;
    height: 171px;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 1100px) {
      display: none;
    }
  }

  .lion2 {
    position: absolute;
    bottom: 0px;
    right: 3vw;
    width: 323px;
    height: 171px;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 1100px) {
      display: none;
    }
  }

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .logo {
    width: 213px;
    margin-top: 36px;
    // margin-right: 89px;
    margin-left: 3vw;
    height: 49px;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 950px) {
      margin: auto 0;
      transform: scale(1.35);
      filter: brightness(0);
    }
  }

  .contacts {
    margin-top: 20px;
    margin-right: 3vw;
    width: 310px;
    height: 60px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    text-align: end;

    @media (max-width: 950px) {
      margin: 0;
      display: block;
      height: auto;
      text-align: center;
    }

    .phone,
    .mail,
    .url {
      display: inline-block;
      background-color: inherit;
      color: inherit;
      text-decoration: none;
      margin-top: 5px;
      z-index: 10;

      @media (max-width: 1200px) {
        margin-left: 5px;
      }

      @media (max-width: 950px) {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }
}