.paragraph {
  padding: 0 5px 0 30px;
  // max-width: 539px;
  padding-bottom: 10px;
  line-height: 1;

  &:nth-child(6) {
    padding-bottom: 0; //check
  }

  .bold {
    display: inline;
    position: relative;
    font-weight: bold;

    &:before {
      content: url(../../../../../assets/meander.svg);
      position: absolute;
      width: 15px;
      top: 0px;
      left: -20px;
    }
  }
}