.orderContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media (max-width: 620px) {
    margin-bottom: 15px;
  }
}

.address {
  display: flex;
  flex-direction: column;
}

.orderContactsHeader {
  font-size: clamp(1.205rem, calc(0.5rem + 1.25vw), 1.415rem);
  text-align: center;
  margin-bottom: 30px;

  @media (max-width: 620px) {
    margin-bottom: 15px;
  }
}

.contactNumber {
  position: relative;
  width: fit-content;
  display: inline-block;
  background-color: inherit;
  color: inherit;
  text-decoration: none;
  margin-top: 5px;

  &:hover {
    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #877065;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
}