@import '../variables';

.wrapper {
  position: relative;
  width: 100%;
  height: 90vh;

  .loaderBlock {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .loader {
      margin: 0 auto 10px auto;
      border: 10px solid #f3f3f3;
      border-top: 10px solid #877065;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      animation: spin 1s linear infinite;

      @-webkit-keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }

    .text {
      font-family: 'Playfair Display', Roboto, system-ui, Tahoma, sans-serif;
      // font-family: 'Roboto Slab', Tahoma, serif;
      font-size: clamp(1.05rem, calc(0.2rem + 1.3vw), 1.25rem);
      // font-family: Montserrat, Roboto, system-ui, Tahoma, sans-serif;
    }
  }
}