.leftPanel {
  width: 329px;
  height: 1306px;
  margin-top: 7px;

  @media (max-width: 1250px) {
    position: absolute;
    top: 0;
    left: 0;
  }
  @media (max-width: 660px) {
    display: none;
  }
}
