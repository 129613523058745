.darkBG {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 101vh;
  z-index: 2;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: fixed;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.centered {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal {
  color: white;
  max-height: 90vh;
  z-index: 9999;
  border-radius: 16px;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);

  @media (max-width: 1150px) {
    max-width: 90vw;
    height: auto;
  }
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  border: none;
  width: 30px;
  height: 30px;
  background: url('../../../assets/UI/closeButton.svg') no-repeat center;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 15px;
  margin-right: 15px;
  z-index: 10;

  @media (max-width: 550px) {
    width: 20px;
    height: 20px;
  }
}

.closeBtn:hover {
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.modalContent {
  .modalBgFix {
    // max-height: 90vh;
    width: 90vw;
    height: 90vh;
    // @media (max-width: 1150px) {
    //   max-width: 90vw;
    //   height: auto;
    // }
  }
}

.modalWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
}

.modalImg {
  margin: 0;
  border-radius: 16px;
  max-height: 90vh;

  @media (max-width: 1150px) {
    max-width: 90vw;
    height: auto;
  }
}

.modalHeader {
  position: absolute;
  top: 0;
  // left: 0;
  // font-family: 'Playfair Display', Roboto, system-ui, Tahoma, sans-serif;
  // font-size: clamp(0.8rem, calc(0.2rem + 1vw), 1rem);
  // font-size: clamp(1.05rem, calc(0.2rem + 1.3vw), 1.25rem);
  font-size: clamp(1.205rem, calc(0.5rem + 1.25vw), 1.415rem);
  text-align: center;
  padding: 15px 0;
  margin: auto 0;
}

.modalNumber {
  // font-family: 'Roboto Slab', Tahoma, serif;
  // font-size: clamp(0.8rem, calc(0.2rem + 1vw), 1rem);
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.modalPrice {
  // font-family: 'Roboto Slab', Tahoma, serif;
  // font-size: clamp(0.8rem, calc(0.2rem + 1vw), 1rem);
  text-align: center;
  padding: 5px 0;
  position: absolute;
  bottom: 15px;
  // left: 0;
}

.card3dModel {
  // font-family: 'Roboto Slab', Tahoma, serif;
  // font-size: clamp(0.8rem, calc(0.2rem + 1vw), 1rem);
  text-align: center;
  color: black;
  padding: 5px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  border: 1px solid rgb(189, 189, 189);
  background-color: rgba(189, 189, 189, 0.3);
}