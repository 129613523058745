.header {
  max-width: 350px;
  margin: 0 auto 30px auto;
  text-align: center;
  font-size: clamp(1.205rem, calc(0.5rem + 1.25vw), 1.415rem);

  @media (max-width: 620px) {
    margin-bottom: 15px;
  }
}

.paragraph {
  padding: 0 5px 0 30px;
  padding-bottom: 10px;

  &:last-of-type {
    padding-top: 10px;
    padding-bottom: 0;
  }
}